<template>
    <!--
    Страница проверки QR-кода
    -->

    <el-container direction="vertical">

        <template v-if="status != null">
            <el-button type="success" class="check-token-button" icon="el-icon-success" v-if="status">Подпись действительна</el-button>
            <el-button type="danger" class="check-token-button" icon="el-icon-warning" v-else>Ошибка проверки подписи</el-button>
        </template>

        <template v-if="status">
            <el-table
                    :data="tableData"
                    border
                    style="width: 100%; height: fit-content">
                <el-table-column
                        prop="tKey"
                        label="Ключ">
                </el-table-column>

                <el-table-column
                        prop="tValue"
                        label="Значение">
                </el-table-column>

            </el-table>

        </template>

    </el-container>

</template>

<script>
import api from '../api'

export default {
    data() {
        return {
            perPage: 5,
            currentPage: 0,

            tableData: [],
            status: null,
        }
    },
    created() {
        this.getTokens();
    },

    computed: {
        totalItems() {
            return this.tableData.length;
        },
        token(){
            return this.$route.params.token;
        }
    },

    methods: {
        getTokens() {
            this.status = null;
                api.validation.validateToken(this.token).then(resp => {
                this.tableData = Object.entries(resp).map(([tKey, tValue]) => ({tKey, tValue}));
                this.status = true;
            }).catch(err => {
                this.status = false;
            });
        },
    }
}
</script>

<style scoped lang="scss">
@import "~@/theme.scss";

.el-pagination {
    margin-top: 15px;
}
.check-token-button{
    height: 45px;
    width: 300px;
    text-align: left;
    margin-bottom: 20px;
}
</style>
